/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@import url("https://fonts.googleapis.com/css2?family=Cantata+One&family=Jost:ital,wght@0,100..900;1,100..900&family=Oleo+Script+Swash+Caps:wght@400;700&display=swap");
/* --- Html Elements--- */
* {
  box-sizing: border-box;
  margin: 0;
  font-family: "Jost", sans-serif;
}
main {
  width: 100%;
  display: -ms-grid;
  display: grid;
  gap: 4rem;
  padding: 2%;
  background-color: rgba(200, 225, 225, 0.25);
  overflow: hidden;
}
body {
  width: 100%;
  margin-bottom: 3.1rem;
}
header {
  position: sticky;
  z-index: 100000;
  color: #ca4766;
  top: 0;
  background: white;
  padding: 0.2% 2%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 2px solid rgba(202, 71, 101, 0.3);
}

/* --- Classes --- */
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.App {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.App-logo {
  position: fixed;
  top: 1%;
  right: -0.5%;
  height: 5rem;
  width: auto;
  border-radius: 50%;
  border: 4px solid #ca4765a0;
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  padding: 0.3rem;
  background-color: white;
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  -o-transition: transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  will-change: transform;
}
.App-logo:hover {
  -webkit-transform: translate(-1vh, 1vh);
  -ms-transform: translate(-1vh, 1vh);
  transform: translate(-1vh, 1vh);
}

.anchorLink {
  text-decoration: none;
  color: #066988;
  cursor: pointer;
}
.menuItem,
.menuItemLink {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: end !important;
}
.menuItem a,
.menuItemLink {
  font-family: "Cantata One", serif !important;
}

#extra_info {
  border-radius: 1rem;
  padding: 1rem;
}

/* Transitions & Animations */

.image {
  opacity: 0;
  -webkit-transform: scale(0) translateZ(0);
  transform: scale(0) translateZ(0);
  -webkit-transition: opacity 2s, -webkit-transform 2s;
  transition: opacity 2s, -webkit-transform 2s;
  -o-transition: opacity 2s, transform 2s;
  transition: opacity 2s, transform 2s;
  transition: opacity 2s, transform 2s, -webkit-transform 2s;
  will-change: opacity, transform;
}

.image-enter-active {
  opacity: 1;
  -webkit-transform: scale(1) translateZ(0);
  transform: scale(1) translateZ(0);
}

.image-responsive {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

/* --- Overridden Component Classes --- */

.css-1fdsijx-ValueContainer {
  padding: 1rem 3.1rem !important;
}
/* .MuiBox-root {
} */
.MuiFormLabel-root {
  border-radius: 1rem 1rem 0 0;
  padding: 0.5rem !important;
}
.MuiFormLabel-root,
.spacingBlock {
  background-color: rgba(200, 225, 225, 0.25);
}
.MuiFormControl-root {
  width: 100%;
  border-radius: 1rem !important;
}
.css-1y3w0gu-MuiFormControl-root {
  margin: 1rem 0 !important;
  background-color: rgba(200, 225, 225, 0.25) !important;
}
.MuiOutlinedInput-root,
.MuiInputBase-input {
  border-radius: 1rem !important;
}
.MuiInputBase-input {
  background-color: white !important;
}

.MuiFormHelperText-root {
  color: red !important;
  font-size: 1rem !important;
}

.Picture_picture__q5wOi {
  border-radius: 2rem;
}
.MuiBox-root.css-15ull9x {
  border-radius: 2rem !important;
}
.MuiBox-root.css-15ull9x h3 {
  font-family: "Cantata One", serif !important;
}
.carousel-indicators {
  margin-bottom: 0 !important;
  bottom: -2rem !important;
}
.carousel-indicators button {
  height: 0.3rem !important;
  background-color: #ca4766 !important;
  border: 1px solid white !important;
}
.carousel-control-prev,
.carousel-control-next {
  width: fit-content !important;
  top: 45% !important;
  background-color: #ca4766 !important;
  height: fit-content;
  border-radius: 50%;
  padding: 1rem !important;
}
.carousel-control-prev {
  left: -1.4rem !important;
}
.carousel-control-next {
  right: -1.4rem !important;
}

.MuiFormHelperText-root {
  text-align: center !important;
}

/* ----Font Classes  --- */
.font_logo {
  font-family: "Cantata One", serif !important;
}
/* --- Media Queries --- */

@media (min-width: 600px) {
  .App-logo {
    height: 5.5rem;
  }
}

@media (min-width: 50rem) {
  .App-logo {
    height: 6rem;
  }
  main {
    gap: 8rem;
  }
}
