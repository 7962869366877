.thanks {
  font-size: 1.2rem !important;
  opacity: 0.8;
}
.groups {
  margin: 0 2%;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 2rem;
  padding: 2rem;
  margin-bottom: 3.5rem;
}
.groups_list {
  max-width: 60rem;
  list-style: none;
  padding: 0;
  margin: 0 auto;
}
.group_list_item {
  padding: 0;
  margin: 0;
}
.group_link {
  display: block;
}

.group_link:first-of-type img {
  max-height: 7rem;
  margin: 0 auto;
  width: auto;
}

@media (min-width: 32rem) {
  .groups {
    padding: 3rem 2rem;
  }
  .groups_list {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    justify-content: end;
    align-items: center;
  }
  .group_list_item {
    justify-self: end;
  }
}

@media (min-width: 48rem) {
  .thanks {
    font-size: 1.5rem !important;
  }
}
