/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.corevalues {
  -ms-grid-column-align: center;
  justify-self: center;
  display: -ms-grid;
  display: grid;
  gap: 5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 2.5rem;
  background-color: white;
  border-radius: 2rem;

  text-align: center;
}
.corevalue {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-items: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
}
.corevalue:nth-of-type(2) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.corevalue_icon {
  display: block;
  margin: 0 auto;
  width: auto;
  max-height: auto;
  max-width: 40%;
  flex-shrink: 2;
}
.corevalue_section {
  width: 60%;
  margin: 0 auto;
  border-bottom: 10px dotted pink;
  padding-bottom: 10px;
  flex-shrink: 1;
}
.corevalue_title {
  color: rgba(100, 150, 255, 1);
  font-size: 1.5rem;
}

@media (min-width: 50rem) {
  .corevalues {
    height: 25rem;
    -ms-grid-columns: 1fr 1rem 1fr 1rem 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    max-width: 67.5rem;
    padding: 1rem;
  }
  .corevalue {
    display: block;
  }

  .corevalue_icon {
    max-width: 50%;
  }
  .corevalue_section {
    width: 100%;
    margin: 0 auto;
    border-bottom: none;
    padding-bottom: 0;
  }
}
