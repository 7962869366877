/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.footer {
  display: -webkit-box; /* Old WebKit browsers */
  display: -ms-flexbox; /* IE10 */
  display: -ms-grid; /* IE10 and IE11 */
  display: grid; /* Standard */

  -ms-grid-columns: 1.35fr 1fr;

  grid-template-columns: 1.35fr 1fr;
  width: 100%;
  cursor: pointer;
  position: fixed;
  z-index: 9999;
  background-color: white;
  bottom: 0;
  left: 0;
  margin-bottom: 0;
  background-color: rgba(202, 71, 101, 0.9);
  -webkit-transition: all 1s; /* For Older Browsers */
  -o-transition: all 1s;
  transition: all 1s;

  height: -webkit-min-content;

  height: -moz-min-content;

  height: min-content;
  height: auto; /* Fallback */
}

.footer_text,
.footer_text-action {
  padding: 1.3rem;
}

.footer_text {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  height: auto; /* Fallback */
  background-color: white;
  -webkit-clip-path: polygon(
    0 0,
    95% 0,
    100% 0%,
    95% 100%,
    0 100%
  ); /* Safari */
  clip-path: polygon(0 0, 95% 0, 100% 0%, 95% 100%, 0 100%);

  padding-right: 2rem;
}

.footer_text-header {
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 1rem !important;
  margin-bottom: -2px;
}

.footer_text-subheader {
  font-size: 1rem;
  letter-spacing: 1px;
  -webkit-transform: scale(1, 0.9);
  -ms-transform: scale(1, 0.9);
  transform: scale(1, 0.9);
  font-size: 0.8rem !important;
}

.footer_text-action {
  font-size: 1rem !important;
  justify-self: flex-start;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

/* Hover styles */
.footer:hover {
  background-color: whitesmoke;
  border-top: 1px solid rgba(202, 71, 101, 0.5);
}
.footer:hover .footer_text {
  background-color: rgba(202, 71, 101, 0.9);
}
.footer:hover .footer_text-header {
  color: white;
}
.footer:hover .footer_text-subheader {
  color: white;
}
.footer:hover .footer_text-action {
  color: rgba(202, 71, 101);
}

@media (min-width: 53rem) {
  .footer {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  .footer_text {
    padding-right: 5rem;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: end;
  }
  .footer_text-header {
    font-size: 1.6rem !important;
  }
  .footer_text-subheader {
    font-size: 1.1rem !important;
  }
  .footer_text-action {
    font-size: 1.3rem !important;
  }
}

@media (min-width: 62rem) {
  .footer_text-action {
    font-size: 1.7rem !important;
  }
}
