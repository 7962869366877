/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.office {
  background: white;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1rem;
  border-radius: 1rem;
}
.office_title {
  color: rgba(202, 71, 101, 0.75);
  font-size: 1.2rem !important;
  font-family: "Cantata One", serif !important;
}
/* .office_main .office_title {
  font-size: 1.6rem !important;
} */
.office_coverage {
  color: rgba(6, 70, 151, 255);
}

.office_address {
  color: rgba(96, 125, 173, 255);
  font-size: 1rem;
}

@media (min-width: 60rem) {
  .office {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fill-available;
    min-width: 24rem;
    border-radius: 0 1rem 1rem 0;
  }
}
