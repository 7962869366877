/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.staff {
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  padding: 1rem 1rem 2rem 1rem;
  background-color: white;
  border-radius: 2rem;
  width: 94vw;
  max-width: 68rem;
  margin: 0 auto;
}

.staff_head {
  position: relative;
  margin: 0 auto;
  height: 12rem;
  width: 100%;
  margin-bottom: 1rem;
  margin-left: 0;
  border-radius: 2rem 2rem 4rem 0;
  background-image: url("../../assests/images//love_hand_gesture/love_hand_gesture_500px.jpg");
  background-position: top 20% left;
  background-size: cover;
}
.staff_head_text {
  position: absolute;
  bottom: 0%;
  left: 0%;
  text-shadow: 0px 0px 4px #000;
  color: white;
  background: rgba(62, 60, 118, 0.2);
  width: 100%;
  padding: 2%;
  font-size: 1.5rem !important;
}
.staff_member {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.staff_member1_thumbail {
  transform: scale(0.6);
  margin-bottom: -2rem;
  position: relative;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
.staff_member2_thumbnail {
  transform: scale(0.6);
  margin-bottom: -2rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
.staff_member_img {
  position: absolute;
  top: -3rem;
  left: 0;
  width: 15rem;
  height: 15rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.staff_member1_img {
  background-image: url("../../assests//images//staff/ana.png");
}

.staff_member2_img {
  background-image: url("../../assests//images//staff/tava.png");
}

.staff_img_background {
  height: 12rem;
  width: 15rem;
  background-color: rgba(70, 150, 255, 0.3);
  border: 1px solid rgba(202, 71, 101, 0.9);
  border-radius: 6rem;
  opacity: 0.5;
}
.staff_member_text {
  color: rgba(100, 150, 255, 1);
}
.staff_member_desciption {
  color: #999;
}

@media (min-width: 30rem),
  (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
  .staff_head {
    background-image: url("../../assests/images//love_hand_gesture/love_hand_gesture_800px.jpg");
  }
}
@media (min-width: 30rem) {
  .staff_head {
    height: 15rem;
    background-position: top 10% left;
  }
}

@media (min-width: 40rem) {
  .staff_member1_thumbail {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .staff_member2_thumbnail {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
}

@media (min-width: 49rem),
  (-webkit-min-device-pixel-ratio: 3),
  (min-resolution: 288dpi) {
  .staff_head {
    background-image: url("../../assests/images//love_hand_gesture/love_hand_gesture_1024px.jpg");
  }
  .staff_head_text {
    font-size: 1.7rem !important;
  }
}

@media (min-width: 49rem) {
  .staff_head {
    height: 19rem;
  }
}
@media (min-width: 55rem) {
  .staff_member {
    gap: 0;
  }
  .staff_member1 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 3rem 0;
  }
  .staff_member2 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .staff_member1_thumbail {
    margin-top: 2rem;
    margin-bottom: 0;
    transform: scale(0.8);
  }
  .staff_member2_thumbnail {
    margin-top: 2rem;
    margin-bottom: 0;
    align-self: flex-start;
    transform: scale(0.8);
  }
}
@media (min-width: 63rem) {
  .staff_head_text {
    font-size: 2.5rem !important;
  }
  .staff_head {
    height: 23rem;
  }
  .staff_head_text {
    font-size: 2rem;
  }
}
